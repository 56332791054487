<template>
    <div>
        <download-csv
                v-if="$store.state.permissions.includes('csv')"
                class="btn btn-info pull-right hand"
                :data="csvData"
                name="UsersData.csv"
        >
            <i class="fa fa-download" /> CSV
        </download-csv>
        <button
                v-if="$store.state.permissions.includes('user-store')"
                type="submit"
                class="btn btn-info float-right"
                @click="showModal"
                style="margin-right: 15px"
        >
            Add User
        </button>
        <b-row class="ml-1 ">
            <h3>All Users</h3>
        </b-row>
        <!-- Filter row -->
        <div class="mt-2 mr-5 d-flex flex-wrap justify-content-between">
            <div class="ml-2">
                <label for="" class="mt-0 d-block font-weight-bold font-12"
                >Records Per Page
                </label>
                <b-form-select class="hand width-select" :options="pageOptions" v-model="perPage" />
            </div>
            <div >
                <div class="ml-2">
                    <label for="" class="mt-0 font-weight-bold font-12"
                    >Filter By User Status</label
                    >

                    <select
                            v-b-tooltip.hover
                            v-model="searchData.user_status"
                            id="filter"
                            name="filter"
                            class="form-control select-single hand"
                            data-placement="Date"
                    >
                        <option selected value="all">All</option>
                        <option value="Subscribed">Subscribed</option>
                        <!-- <option value="Active">Active</option>-->
                        <option value="Archived">Archived</option>
                        <option value="Inactive">Inactive</option>
                        <option value="Lead">Lead</option>
                    </select>
                </div>
            </div>
            <div class="ml-2">
                <div>
                    <label for="" class="mt-0 font-weight-bold font-12"
                    >Filter Employees/Employers</label
                    >

                    <select
                            v-b-tooltip.hover
                            v-model="searchData.is_employee"
                            id="filter2"
                            name="isEmployee"
                            class="form-control select-single hand"
                            data-placement="Date"
                    >
                        <option value="all">All</option>
                        <option selected :value="true">Employer</option>
                        <option :value="false">Employee</option>
                    </select>
                </div>
            </div>
            <div class="ml-2">
                <label for="" class="mt-0 d-block font-weight-bold font-12 ">Companies:</label>
                <select2
                        v-model="searchData.selected_company_id"
                        placeholder="select company"
                        class="form-control no-border-radius select2 custom-select hand "
                >
                    <option value="all">All</option>
                    <option
                            v-for="(company, index) in companies"
                            :key="index"
                            :value="company.hash_id"
                    >
                        {{ company.name }}
                    </option>
                </select2>
            </div>
            <!--            date range start from here-->
            <div class="ml-2">
                <div>
                    <label for="" class="mt-0 font-weight-bold font-12"
                    >Date range
                    </label>
                    <div class="daterange" style="width:300px !important">
                        <i class="fa fa-calendar place" />
                        <span class="daterange-dis ml-2" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Users details table -->
        <b-row class="mt-3" v-if="selected_company_id">
            <b-col>
                <b-table
                         id="users_table"
                        small
                        responsive="true"
                        :busy.sync="isBusy"
                        show-empty
                        empty-text="No users found for this company"
                        striped
                        bordered
                        hover
                        :items="items"
                        :fields="fields"
                        :current-page="currentPage"
                        :filter="search"
                        @filtered="onFiltered"
                        class="break "
                        style="overflow:scroll!important;"

                >
                <template slot="top-row" slot-scope="{ fields }">
                    <td v-for="field in fields" :key="field.key">
                        <input v-model="searchData.name" :placeholder="field.label"  v-if="field.key =='name'" class="input-search">
                        <input v-model="searchData.email" :placeholder="field.label"  v-if="field.key =='email'" class="input-search">
                        <!--                        <select-->
                        <!--                            v-b-tooltip.hover-->
                        <!--                            v-model="searchData.is_employee"-->
                        <!--                            id="filter2"-->
                        <!--                            name="isEmployee"-->
                        <!--                            class="form-control select-single hand"-->
                        <!--                            data-placement="Date"-->
                        <!--                            v-if="field.key =='isEmployer'"-->
                        <!--                        >-->
                        <!--                            <option value="all">All</option>-->
                        <!--                            <option selected :value="true">Employer</option>-->
                        <!--                            <option :value="false">Employee</option>-->
                        <!--                        </select>-->
                    </td>
                </template>
                <template v-slot:cell(image)="data">
                        <img
                                v-if="data.value"
                                :id="data.value"
                                :src="
                data.value.includes('https:')
                  ? data.value
                  : 'https://api.stafftimerapp.com/storage/' + data.value
                  "
                                width="40px"
                                height="40px"
                        />

                        <img
                                v-else
                                src="https://via.placeholder.com/40/10659C/FFFFFF?text=STA"
                                :id="data.item.id"
                                width="40px"
                                height="40px"
                        />

                        <b-popover
                                v-if="data.value"
                                :target="data.value"
                                :key="data.value"
                                triggers="hover"
                        >
                            <img
                                    v-if="data.value"
                                    :src="
                  data.value.includes('https:')
                    ? data.value
                    : 'https://api.stafftimerapp.com/storage/' + data.value
                    "
                                    class="image"
                            />
                        </b-popover>

                        <b-popover
                                v-if="data.item.id"
                                :target="data.item.id"
                                :key="data.item.id"
                                triggers="hover"
                        >
                            <img
                                    src="https://via.placeholder.com/200/10659C/FFFFFF?text=STA"
                                    class="image"
                            />
                        </b-popover>
                    </template>

                    <template v-slot:cell(action)="cell">
                        <!-- We use click.stop here to prevent a 'row-clicked' event from also happening -->
                        <div class="d-flex flex-wrap justify-content-around align-items-center">
                        <span class="impersonate">
                            <i
                                    v-if="$store.state.permissions.includes('impersonate')"
                                    @click.stop="impersonate(cell.item.encrypted_id)"
                                    size="sm"
                                    title="Impersonate this user"
                                    v-b-tooltip.hover
                            ><i class="fa fa-bug hand fa-style"
                            /></i>
                        </span>
                        <span>
                            <i
                                    v-if="$store.state.permissions.includes('email-detail-list')"
                                    @click.stop="detailModel(cell.item.encrypted_id)"
                                    class="ml-2"
                                    size="sm"
                                    title="Email Detail"
                                    v-b-tooltip.hover
                            ><i class="fa fa-eye hand fa-style"
                            /></i>
                        </span>
                        <span
                                class="archive"
                                v-if="cell.item.user_archived_status === 'Archived'"
                        >
                            <i
                                    v-if="$store.state.permissions.includes('user-restore')"
                                    @click="restoreMember(cell.item.id)"
                                    size="sm"
                                    title="Restore"
                                    v-b-tooltip.hover
                            ><i class="fa fa-window-restore hand fa-style"
                            /></i>
                        </span>
                        <span
                                class="unarchive"
                                v-else-if="cell.item.user_archived_status === 'Unarchived'"
                        >
              <i
                      v-if="$store.state.permissions.includes('user-archive')"
                      @click="archiveMember(cell.item.id)"
                      size="sm"
                      title="Archive"
                      v-b-tooltip.hover
              ><i class="fa fa-archive hand fa-style"
              /></i>
                        </span>
                        <span>
                            <i
                                    v-if="$store.state.permissions.includes('user-logs')"
                                    @click.stop="userLog(cell.item.id)"
                                    class="ml-2"
                                    size="sm"
                                    title="Users Logs"
                                    v-b-tooltip.hover
                            ><i class="fa fa-user hand fa-style"
                            /></i>
                        </span>
                        </div>
                    </template>

                    <template v-slot:cell(ownedCompanies)="cell">
                        <div :id="'OwnedCompanies' + cell.item.id">
                            {{ cell.item.ownedCompanies }}
                        </div>
                        <b-popover
                                :target="'OwnedCompanies' + cell.item.id"
                                triggers="hover , click"
                                v-if="cell.item.ownedCompanies"
                        >
                            <li
                                    :key="index"
                                    class="list"
                                    v-for="(item, index) in cell.item.ownedCompaniesList"
                            >
                                {{ item }}
                            </li>
                        </b-popover>
                    </template>
                    <template v-slot:cell(joinedCompanies)="cell">
                        <div :id="'JoinedCompanies' + cell.item.id" class="w-100">
                            {{ cell.item.joinedCompanies }}
                        </div>

                        <b-popover
                                :target="'JoinedCompanies' + cell.item.id"
                                triggers="click , hover"
                                v-if="cell.item.joinedCompanies"
                        >
                            <li
                                    :key="index"
                                    class="list"
                                    v-for="(item, index) in cell.item.joinedCompaniesList"
                            >
                                {{ item }}
                            </li>
                        </b-popover>
                    </template>

                    <template v-slot:cell(source)="data">
                        <span>{{ data.value }}</span>
                    </template>
                </b-table>
                <b-row>
                    <div class="my-1">
                        <!--     <b-pagination
                                align="right"
                                aria-controls="companies_table"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                v-model="currentPage"
                        />-->
                        <b-pagination align="right" aria-controls="users_table" :total-rows="pagination.total"
                                      :per-page="pagination.per_page" v-model="currentPage"/>
                    </div>
                </b-row>
            </b-col>
        </b-row>


        <EmailsDetail />
        <add-user-modal :modelopen="open" :userRole="role" />
        <edit-user-modal
                :modelOpen="open"
                :userRole="role"
                :userName="user_name"
                :selectRole="editper"
                :userHash="user_hash_id"
                ref="editdata"
        />
        <!--        <UserLogs/>-->
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import select2 from "../components/assets/select2";
    import moment from "moment";
    import $ from "jquery";
    import "daterangepicker/daterangepicker.js";
    import "daterangepicker/daterangepicker.css";
    import addUserModal from "../components/addUser/addUserModal";
    import editUserModal from "../components/addUser/editUserModal";
    import _ from 'lodash';

    export default {
        name: "Users",
        components: {
            select2,
            addUserModal,
            editUserModal,
            EmailsDetail: () => import("@/components/emails/EmailsDetail")
        },
        props: ["modelopen", "userRole", "userName", "selectRole"],
        data() {
            return {
                user_name: "",
                user_hash_id: "",
                searchData : {
                    name: null,
                    email : null,
                    is_employee : true,
                    user_status : 'all',
                    selected_company_id : "all",
                },
                editper: [],
                role: [],
                open: false,
                company_id: "all",
                selected_company_id: "all",
                start: moment().subtract(29, "days"),
                end: moment(),
                start_date: moment()
                    .subtract(29, "days")
                    .format("YYYY-MM-DD"),
                end_date: moment().format("YYYY-MM-DD"),
                fields: [
                    {
                        key: "image",
                        label: "Image",
                        thStyle: {
                            width: "33px"
                        }
                    },
                    {
                        key: "name",
                        label: "Name",
                        sortable: true,
                        thStyle: {
                            width: "130px"
                        }
                    },
                    {
                        key: "email",
                        label: "Email",
                        sortable: true,
                        thStyle: {
                            width: "150px"
                        }
                    },
                    {
                        key: "phone_number",
                        label: "Phone",
                        sortable: true,
                        thStyle: {
                            width:" 90px"
                        }
                    },
                    {
                        key: "isEmployer",
                        label: "Is Employer",
                        sortable: true,
                        thStyle: {
                            width: "56px"
                        }
                    },
                    {
                        key: "ownedCompanies",
                        label: "Owned Companies",
                        sortable: true,
                        thStyle: {
                            width: "63px"
                        }
                    },
                    {
                        key: "joinedCompanies",
                        label: "Joined Companies",
                        sortable: true,
                        thStyle: {
                            width: "64px"
                        }
                    },
                    {
                        key: "appVersion",
                        label: "App Version",
                        sortable: true,
                        thStyle: {
                            width: "49px"
                        }
                    },
                    {
                        key: "screenshot_count",
                        label: "ScreenShot Count",
                        sortable: true,
                        thStyle: {
                            width: "49px"
                        }
                    },
                    {
                        key: "last_capture_time",
                        label: "Last Capture",
                        sortable: true,
                        thStyle: {
                            width: "110px"
                        }
                    },
                    {
                        key: "created_at",
                        label: "Joined At",
                        sortable: true,
                        thStyle: {
                            width: "90px"
                        }
                    },
                    {
                        key: "user_status",
                        label: " User Status",
                        sortable: true,
                        thStyle: {
                            width: "41px"
                        }
                    },
                    {
                        key: "source",
                        label: "SignUp Source",
                        sortable: true,
                        thStyle: {
                            width: "61px"
                        }
                    },
                    {
                        key: "action",
                        label: "Action",
                        thStyle: {
                            width: "70px"
                        }
                    }
                ],
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                search: null,
                totalRows: 0,
                filter: "all",
                isEmployee: true
            };
        },
        computed: {
            ...mapState(["users"]),
            items() {
                return this.users;
                let filteredUsers =
                    this.filter === "all"
                        ? this.users.filter(
                        users => users.user_archived_status !== "Archived"
                        )
                        : this.users.filter(
                        users =>
                            users.user_status === this.filter ||
                            users.user_archived_status === this.filter
                        );
                // console.log(this.users)
                return this.isEmployee === "all"
                    ? filteredUsers
                    : filteredUsers.filter(users => users.isEmployer === this.isEmployee);
            },
            pagination(){
                return this.$store.state.pagination;
            },
            csvData() {
                return this.$store.state.users.map((item, index) => ({
                    Index: index + 1,
                    Name: item.name,
                    Email: item.email,
                    "App Version": item.appVersion,
                    "Owned Companies": item.ownedCompanies,
                    "Joined Companies": item.joinedCompanies,
                    "Created At": item.created_at,
                    "User Status": item.user_status
                }));
            },
            isBusy() {
                return this.$store.state.isBusy;
            },
            companies() {
                return this.$store.state.all_companies;
            },
            selectedCompany() {
                return this.companies.find(
                    company => parseInt(company.id) === parseInt(this.selected_company_id)
                );
            }
        },
        watch: {
            selected_company_id: function(id) {
                let data = {
                    from: this.start_date,
                    to: this.end_date,
                    company_id: id
                };
                this.get_users(data);
            },
            currentPage(){
                this.getDashboardData();
            },

            'searchData.name': {
                handler: _.debounce(function () {
                        this.getDashboardData();
                    }
                    , 1000),
                deep:true
            },

            'searchData.email': {
                handler: _.debounce(function () {
                        this.getDashboardData();
                    }
                    , 10),
                deep:true
            },

            'searchData.is_employee': {
                handler: _.debounce(function () {
                        this.getDashboardData();
                    }
                    , 10),
                deep:true
            },

            'searchData.user_status': {
                handler: _.debounce(function () {
                        this.getDashboardData();
                    }
                    , 10),
                deep:true
            },
            'searchData.selected_company_id': {
                handler: _.debounce(function () {
                        this.getDashboardData();
                    }
                    , 10),
                deep:true
            },
            perPage(){
                this.getDashboardData();
            }

        },
        mounted() {
            this.totalRows = this.items.length;
            this.dateRangeStart();
            this.getDashboardData();
            this.$store.dispatch("getAllCompanies");
            // this.getUserRoleList();
        },
        methods: {
            // getUserRoleList() {
            //     this.$store
            //         .dispatch("getUserRole")
            //         .then(response => {
            //             this.role = response.data.roles;
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         });
            // },
            detailModel(user) {
                this.$root.$emit("bv::show::modal", "detailsModel");
                this.$root.$emit("selected_user", user);
            },
            showModal() {
                this.open = true;
                let element = document.getElementById("exampleModal");
                $(element).modal("show");
            },
            userLog(id) {
                let routeData = this.$router.resolve({
                    name: "users_logs",
                    params: { id }
                });
                window.open(routeData.href, "_blank");
            },

            userArchivedDelete(id) {
                this.$store.commit("toggle_loader", true);
                let data = {
                    user_id: id
                };
                this.$store
                    .dispatch("userArchivedDelete", data)
                    .then(response => {
                        this.$store.commit("toggle_loader", false);
                        this.getDashboardData();
                    })
                    .catch(() => {
                        this.$store.commit("toggle_loader", false);
                    });
            },
            impersonate(id) {
                let url = "https://backoffice.stafftimerapp.com/user-login/" + id;
                let win = window.open(url, "_blank");
                win.focus();
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            get_users(company) {
                this.$store.dispatch("getUsers", company);
            },

            dateRangeStart() {
                // if(this.start.format('DD/MM/YYYY') == '01/01/2017') {
                //     $('#daterange span').html('All time');
                // }else {
                //     $('#daterange span').html(this.start.format('MMMM D, YYYY') + ' - ' + this.end.format('MMMM D, YYYY'));
                // }
                $(".daterange span").html(
                    this.start.format("MMM D, YYYY") +
                    " - " +
                    this.end.format("MMM D, YYYY")
                );
                $(".daterange").daterangepicker(
                    {
                        startDate: this.start,
                        endDate: this.end,
                        opens: "left",
                        ranges: {
                            "Last 30 Days": [moment().subtract(29, "days"), moment()],
                            Today: [moment(), moment()],
                            Yesterday: [
                                moment().subtract(1, "days"),
                                moment().subtract(1, "days")
                            ],
                            "Last 7 Days": [moment().subtract(6, "days"), moment()],
                            "This Month": [moment().startOf("month"), moment().endOf("month")],
                            "Last Month": [
                                moment()
                                    .subtract(1, "month")
                                    .startOf("month"),
                                moment()
                                    .subtract(1, "month")
                                    .endOf("month")
                            ],
                            "Last Year": [moment().subtract(1, "year"), moment()],
                            'All time': [moment('2017-01-01'), moment()],
                        }
                    },
                    this.dateRangeChange
                );
            },

            dateRangeChange(start, end) {
                $(".daterange span")
                    .html(start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY"))
                    .addClass("pl-2 pr-1");
                this.start_date = start.format("YYYY-MM-DD");
                this.end_date = end.format("YYYY-MM-DD");

                this.getDashboardData();
            },

            getDashboardData() {
                this.$store.commit("toggle_loader", true);
                if (this.selected_company_id == null) {
                    this.selected_company_id = "all";
                }
                let data = {
                    from: this.start_date,
                    to: this.end_date,
                    company_id: this.selected_company_id,
                    per_page: this.perPage,
                    page: this.currentPage,
                    search: this.searchData
                };
                this.$store.dispatch("getUsers", data).then(() => {
                    this.$store.commit("toggle_loader", false);
                });
            },

            archiveMember(id) {
                let data = {
                    user_id: id
                };
                this.$store.dispatch("archiveUser", data);
                this.getDashboardData();
            },

            restoreMember(id) {
                let data = {
                    user_id: id
                };
                this.$store.dispatch("restoreUser", data);
                this.getDashboardData();
            },

            editModel(user) {
                let data = {
                    user_id: user.id
                };
                this.user_name = user.name;
                this.user_hash_id = user.id;
                this.$store.commit("toggle_loader", true);
                this.$store
                    .dispatch("editUser", data)
                    .then(response => {
                        this.$store.commit("toggle_loader", false);
                        this.role = response.data.all_roles;
                        this.editper = response.data.selected_roles;
                        let perm = [];
                        this.editper.forEach(function(item) {
                            perm.push(item.hash_id);
                        });
                        this.editper = perm;
                        this.$nextTick(() => {
                            this.$refs.editdata.isLoop();
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
                this.open = true;
                let element = document.getElementById("exampleModal4");
                $(element).modal("show");
            }
        }
    };
</script>

<style scoped>
.width-select{
    min-width: 130px!important;
}
.overflow-hidden{
    overflow: hidden!important;
}
.overflow-scroll{
    overflow: scroll!important;
}
    .daterange-dis {
        font-size: smaller;
    }

    .archive {
        margin-left: 10px;
    }

    .unarchive {
        margin-left: 10px;
    }

    .impersonate {
        margin-left: 10px;
    }

    .list {
        list-style-type: none;
        padding: 4px;
        box-shadow: 0 2px 4px 0 rgba(167, 161, 161, 0.5);
    }

    .image {
        max-height: 200px;
        min-height: 200px;
        max-width: 200px;
        min-width: 200px;
        border-radius: 3px;
    }

    .margin {
        margin-left: -15px;
    }

    .flex5 {
        margin-left: -20px;
    }

    .fa-style {
        font-size: 16px;
        padding-top: 3px;
    }
</style>
