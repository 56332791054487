<template>
    <div id="example-modal">
        <!-- Modal -->
        <div
                aria-hidden="true"
                aria-labelledby="exampleModalLabel"
                class="modal fade"
                id="exampleModal"
                ref="modal"
                role="dialog"
                tabindex="-1"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add User</h5>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form ref="account_form" @submit="submit">
                            <b-form-group
                                    class="col-md-12 col-lg-12 col-xl-12 col-sm-12 b-form-group form-group"
                            >
                                <label>Name</label>
                                <b-form-input
                                        required
                                        name="name"
                                        v-model="name"
                                        autocomplete="username"
                                        placeholder="Name"
                                >
                                </b-form-input>

                                <div class="message">
                                    {{ validation.firstError("name") }}
                                </div>
                            </b-form-group>
                            <b-form-group
                                    class="col-md-12 col-lg-12 col-xl-12 col-sm-12 b-form-group form-group"
                            >
                                <label>Email</label>
                                <b-form-input
                                        required
                                        name="email"
                                        v-model="email"
                                        autocomplete="username"
                                        placeholder="Email"
                                >
                                </b-form-input>

                                <div class="message">
                                    {{ validation.firstError("email") }}
                                </div>
                            </b-form-group>
                            <b-form-group
                                    class="col-md-12 col-lg-12 col-xl-12 col-sm-12 b-form-group form-group"
                            >
                                <label>Contact Details</label>
                                <b-form-input
                                        required
                                        name="phone_number"
                                        v-model="phone_number"
                                        autocomplete="phone_number"
                                        placeholder="Phone Number"
                                >
                                </b-form-input>
                                <span
                                        v-if="users_erros.invalidphone_number && phone_number !== ''"
                                        class="errorfield"
                                >please add phone number
                                </span>

                                <div class="message">
                                    {{ validation.firstError("phone_number") }}
                                </div>
                            </b-form-group>
                            <div class="modal-footer">
                                <VueLoadingButton
                                        aria-label="Close"
                                        class="btn btn-secondary"
                                        data-dismiss="modal"
                                        :disabled="state.isSending"
                                >Close
                                </VueLoadingButton>

                                <div>
                                    <div class="example">
                                        <VueLoadingButton
                                                aria-label="Post message"
                                                class="btn btn-primary"
                                                @click.native="submit"
                                                :loading="isLoading"
                                                :styled="!isStyled"
                                        >Submit
                                        </VueLoadingButton>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div
                class="modal fade"
                ref="modal1"
                id="exampleModal1"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel1">Link</h5>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <input
                                class="tab-head word-wrap copy-input"
                                type="text"
                                v-model="link"
                                id="wall_add"
                        />
                        <img
                                src="../../assets/images/copy.jpg"
                                class="icon-img"
                                @click="copy"
                                id="copy_icon"
                        />

                        <b-tooltip
                                ref="tooltip"
                                target="copy_icon"
                                triggers="focus"
                                disabled
                        >
                            <b>Copied</b>
                        </b-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import $ from "jquery";
    import VueLoadingButton from "vue-loading-button";
    import SimpleVueValidation from "simple-vue-validator";

    const Validator = SimpleVueValidation.Validator;
    Vue.use(SimpleVueValidation);
    export default {
        components: {
            name: "addUserModal",
            VueLoadingButton
        },
        props: ["modelopen", "userRole"],

        data() {
            return {
                name: "",
                email: "",
                phone_number: null,
                link: "",
                isLoading: false,
                isStyled: false,
                users_erros: {
                    invalidEmail: false,
                    invalidphone_number: false
                },
                state: {
                    isSending: false
                }
            };
        },

        validators: {
            name: function(value) {
                return Validator.value(value).required("Please enter name.");
            },
            email: function(value) {
                return Validator.value(value)
                    .required("Please enter the email.")
                    .email();
            },
            phone_number: function(value) {
                return Validator.value(value).digit().minLength(6).required("Please enter the phone number")
            }
        },

        mounted() {
            this.opened = this.modelopen;
            if (this.opened === true) {
                let element = document.getElementById("exampleModal");
                $(element).modal("show");
            }
        },

        methods: {
            submit() {
                var self = this;
                this.$validate().then(function(pass) {
                    if (pass === true) {
                        self.handleSubmit();
                    }
                });
            },
            handleSubmit(evt) {
                this.state.isSending = true;
                this.isLoading = true;
                let element = document.getElementById("exampleModal");
                $(element).modal("show");
                let formData = new FormData(this.$refs.account_form);
                formData.append("name", this.name);
                formData.append("email", this.email);
                formData.append("phone_number", this.phone_number);
                formData.append("agreement", 1);
                this.$store
                    .dispatch("addUser", formData)
                    .then(response => {
                        this.isLoading = false;
                        this.state.isSending = false;
                        this.name = "";
                        this.email = "";
                        this.phone_number = "";
                        if (response.data.error === false) {
                            let element1 = document.getElementById("exampleModal");
                            $(element1).modal("hide");
                            let element = document.getElementById("exampleModal1");
                            $(element).modal("show");
                            this.$notify("Success!", response.data.error_msg, "success");
                            this.link = response.data.data.link;
                        }
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.state.isSending = false;

                        if (error.response) {
                            if (error.response.data.errors.email) {
                                this.$notify("Error!", error.response.data.errors.email);
                            }
                            if (error.response.data.errors.phone_number) {
                                this.$notify("Error!", error.response.data.errors.phone_number);
                            }
                            if (error.response.data.errors.name) {
                                this.$notify("Error!", error.response.data.errors.name);
                            }
                        }

                        this.$store.commit("custom/toggle_loader", false);
                    });
                this.$refs.exampleModal.hide();
                this.name = "";
                this.email = "";
                this.phone_number = "";
            },

            copy() {
                let copyText = document.getElementById("wall_add");
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                document.execCommand("copy");
                this.$refs.tooltip.$emit("open");
                let a = this;
                setTimeout(function() {
                    a.$refs.tooltip.$emit("close");
                }, 3000);
            }
        }
    };
</script>

<style scoped>
    .break-link {
        word-break: break-all;
    }

    .copy-input {
        border: none;
        background: transparent;
        width: 92%;
        outline: none;
        pointer-events: none;
    }

    .icon-img {
        width: 16px;
        max-width: 100%;
        margin-left: 1.5%;
    }

    .errorfield {
        color: #ff0000;
        font-size: 14px;
        text-align: left;
        display: block;
        padding: 5px 0 0;
    }

    .message {
        color: #ff0000;
        font-size: 14px;
        text-align: left;
        display: block;
        padding: 5px 0 0;
    }

    .example {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*margin-top: 60px;*/
        text-align: center;
    }
</style>
<style>
    .mydrop {
        width: 94% !important;
        margin-left: 3% !important;
    }

    .d-block {
        margin-left: 3% !important;
    }

    .default-styles {
        padding: 5px 16.5px 5px 16px !important;
        background-color: #17a2b8 !important;
        border-color: #17a2b8 !important;
    }
</style>
